import * as React from "react"

export default function IconBallotBox() {
  return (
    <svg 
      width="33" 
      height="38" 
      viewBox="0 0 33 38" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
    >
        <path 
          d="M25.7937 14.8439V16.15H6.85066v-1.33h1.11546L10.6213 9.5l-3.77064.00001L.0546875 20.14H32.6754l-5.3376-8.36-1.5441 3.0639z" 
          fill="black"
        />
        <path 
          d="M22.8976 14.82h1.3779l4.1724-9.12508L16.6893 0 9.48242 14.82H22.8976zM32.6754 21.7144H.0546875v15.96H32.6754v-15.96z" 
          fill="black"
        />
    </svg>
  )
}