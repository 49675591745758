import React, { useContext, useState, useEffect } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { graphql } from "gatsby"

import WardContext from '../components/WardContext';

import Seo from "../components/Seo"
import Hero from '../components/Hero'
import IconBallotBox from '../components/icons/IconBallotBox'
import CityMapSection from '../components/CityMapSection'
import { Link } from 'gatsby'
import SignUpSection from '../components/SignUpSection';

const PlaceItem = ({ name, address, link}) => (
  <div className="place">
    <div className="place__icon">
      <IconBallotBox />
    </div>
    <div className="place__info">
      <p className="place__name">{ name }</p>
      <p className="place__address">{ address }</p>
      <Link className="place__link" to={`https://www.google.es/maps/place/${address.replace(" ","+")},+UK`}>Get directions ›</Link>
    </div>
  </div>
)

const PlacesSection = ({ votingPlaces, currentWard }) => {

  return (
    <>
      <div className="container container--section-headline">
        <div className="row">
          <div className="col-md-12 headline-wrapper">

            <div className="background-margin"></div>
            <h3 className="places__headline">Your voting place in <b>{currentWard.replace("_"," ")}</b></h3>

          </div>
        </div>
      </div>

      <div className="places">

        <div className="container places__section">

          <div className="row places__row">

            <div className="col-md-12">
              {
                votingPlaces?.map( place => 
                  <PlaceItem
                    key={ place?.location_name } 
                    name={place?.location_name} 
                    address={ place?.location_address }
                  />
                )
              }
            </div>

          </div>

          <div className="row">
            <div className="col-md-12">
              <Link to="/candidates" className="button">Meet your candidates ›</Link>
            </div>
          </div>

        </div>

      </div>
    </>
  )
}

export default function VotingPlaces({ data }) {
  const [ currentWard ] = useContext(WardContext);
  const [ votingPlaces, setVotingPlaces ] = useState([]);

  const dataVotingPlaces = data.allMarkdownRemark.nodes;

  // = = = = = = = = 
  // Hooks

  // Filter candidates when currentWard changes
  const getVotingPlaces = () => {
    const filteredPlaces = dataVotingPlaces?.filter( place => place?.frontmatter?.ward_name === currentWard.replace("_"," ") );
    setVotingPlaces(filteredPlaces[0]?.frontmatter?.ward_voting_places);
  }

  useEffect( () => getVotingPlaces(), [currentWard]);

  return(
    <>
      <Seo title="Voting places" />

      <Hero 
        headline="Find your voting place"
        size = {'medium'}
      >
        <StaticImage 
          className="hero__picture"
          src="../../static/media/updates-hero.jpg" 
          alt="" 
          loading="eager"
        />
      </Hero>

      <CityMapSection headline={ 'Select your ward in the map to find your voting place:' }/>
        
      {
        currentWard
          ? <PlacesSection votingPlaces={ votingPlaces } currentWard={ currentWard } />
          : ''
      }

      <SignUpSection />
    </>
  )
}


// = = = = = = = = 
// GraphQL
export const query = graphql`
  query VotingPlacesQuery {
    allMarkdownRemark(filter: {frontmatter: {type: {eq: "ward"}}}) {
      nodes {
        frontmatter {
          ward_name
          ward_voting_places {
            location_address
            location_name
          }
        }
      }
    }
  }
`;